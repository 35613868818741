import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { getStorageData } from "../../../framework/src/Utilities";

export interface CityList {
  name:string,
  code:string
}
export interface ApiRes {
  message? : string,
  error? : [string]
}
export interface IInputError {
  email: string;
  phoneNumber: string;
  companyName: string;
  mcNumber:string;
  originAddress: string;
  city: string;
  zipCode: string;
  originState: string;
  selectedDate: string;
  printedName: string;
  signatureFile: string;
}
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  // Customizable Area Start
  navigation: object;
  id: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  companyName: string;
  email: string;
  token: string;
  phoneNumber: string;
  mcNumber:string;
  inputErrors: IInputError;
  originAddress: string;
  city: string;
  zipCode: string;
  originState: CityList | null;
  fax: string;
  selectedDate: Date | null;
  printedName: string;
  isDisable: boolean;
  sigCanvasRef: any;
  fileInputRef : any;
  signImg: string;
  signatureFile: File | null;
  signaturePadFile: File | null;
  successMsg: string;
  errorMsg: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;
  // Customizable Area End
}

export default class InvoiceBillingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sendApiDataId: string = "";
  getApiDataId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      companyName: '',
      token: '',
      successMsg:'',
      errorMsg:'',
      email: '',
      phoneNumber: '',
      mcNumber:'',
      inputErrors: {} as IInputError,
      originAddress: '',
      city: '',
      zipCode: '',
      originState: null,
      fax: '',
      selectedDate: null,
      printedName: '',
      isDisable: true,
      signatureFile: null,
      signaturePadFile: null,
      signImg:"",
      sigCanvasRef: React.createRef(),
      fileInputRef : React.createRef(),
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(webApiRequestCallId === this.sendApiDataId){
        this.handlePostAPIResponse(webResponseJson);
      }
  }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const token =  await getStorageData('LoginToken')
    this.setState({token: token}, ()=>{
      this.getPoaData()
    })
  }
  handlePostAPIResponse = (response : ApiRes) => {
    if(response.message === "Power Attorney craete successfully"){
      this.setState({successMsg: "Form submitted successfully!",
        email: '', 
        phoneNumber: '',
        zipCode : '',
        fax : '',
        printedName: '', 
        companyName: '', 
        mcNumber: '', 
        originAddress: '', 
        city: '', 
        originState: null, 
        selectedDate: null, 
        signatureFile: null, 
        signaturePadFile: null
      },
        ()=> setTimeout(()=>{
          this.setState({successMsg: ""})
        },3000)
      )      
    }
    if(response.error){
      this.setState({errorMsg: response.error[0]},
        ()=> setTimeout(()=>{
          this.setState({errorMsg: ""})
        },3000)
      )      
    }
  }
  handleNameValidation = (updateError : IInputError)=>{
    if (!this.state.companyName) {
      updateError.companyName = `Company Name is required.`
    } else if (this.state.companyName.length < 3 || this.state.companyName.length > 50) {
      updateError.companyName = `Company Name must be between 3 and 50 characters long.`
    }

    if(!this.state.mcNumber){
      updateError.mcNumber = "MC Number is required"
    } else if (this.state.mcNumber.length > 10) {
      updateError.mcNumber = "Max 10 characters allowed for the MC number.";
    } 

    if(!this.state.selectedDate){
      updateError.selectedDate = "Please select Date"
    }
  }

  handleValidate = () => {
    let updateError = {...this.state.inputErrors} as IInputError
    const { email, phoneNumber,zipCode ,printedName, companyName} = this.state;

    this.handleNameValidation(updateError)

    if(!this.state.originAddress){
      updateError.originAddress = "Address is required"
    }
    if(!this.state.city){
      updateError.city = "City is required"
    }
    if(!this.state.originState){
      updateError.originState = "State is required"
    }

    if (!zipCode) {
      updateError.zipCode = "Zip Code is required";
    }
    if(!(/^\+1\d{10}$/.test(phoneNumber))){
      updateError.phoneNumber = "The phone number must be exactly 10 digits after '+1."
    } 
    if(!(/^(?!.*\.\..*)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email))){
      updateError.email = "Please enter a valid email address."
    }  
    if(!printedName){
      updateError.printedName = "Printed name is required."
    } else if (companyName !== printedName){
      updateError.printedName = "Printed name and Company name must be same!."
    }
    if(!this.state.signatureFile && this.state.sigCanvasRef.current.isEmpty()){      
      updateError.signatureFile = "Signature is required"
    }
    this.setState({inputErrors:updateError});

    return Object.values(updateError).every(value => value === ""); 
  }
  handleUploadPicture = () => {
    if (this.state.fileInputRef.current) {
      this.state.fileInputRef.current.click(); 
    }
  };
  handleImageChange = (event:any) => {
    const file = event.target.files[0];
    if (file) {
      this.setState((prevState) => ({
        ...prevState,
        signatureFile: file,
        inputErrors: { ...prevState.inputErrors, [`signatureFile`]: '' },
      }) as Pick<S, keyof S>, 
      ()=> this.isFormValid());
    }
  };
  base64ToBlob = (base64: string) => {
    const byteString = atob(base64.split(",")[1]); 
    const mimeString = base64.split(",")[0].split(":")[1].split(";")[0]; 
  
    const abcd = new ArrayBuffer(byteString.length);
    const iaaa = new Uint8Array(abcd);
    for (let index = 0; index < byteString.length; index++) {
      iaaa[index] = byteString.charCodeAt(index);
    }
  
    return new Blob([abcd], { type: mimeString });
  };
  handleOnBegin = () => {
    this.setState((prevState) => ({
      inputErrors: { ...prevState.inputErrors, signatureFile: '' },
    }));
  }
  handleSignaturePad = () => {
    const base64String  = this.state.sigCanvasRef.current.getTrimmedCanvas().toDataURL("image/png")
    const blob = this.base64ToBlob(base64String);
    const file = new File([blob], "signature.png", { type: "image/png" });
    this.setState((prevState) => ({
      ...prevState,
      signaturePadFile: file,
      inputErrors: { ...prevState.inputErrors, [`signatureFile`]: '' },
    }) as Pick<S, keyof S>, 
    ()=> this.isFormValid());
  }
  handleSubmitForm = async() => {
    this.handleValidate()
    const { token, email, phoneNumber,zipCode ,fax ,printedName, companyName, mcNumber, originAddress, city, originState, selectedDate, signatureFile, signaturePadFile } = this.state;
   
    const formData = new FormData();
    formData.append('[power_attorney]company_name', companyName)
    formData.append('[power_attorney]email',email.trim());
    formData.append('[power_attorney]mc_number', mcNumber);
    formData.append('[power_attorney]address', originAddress);
    formData.append('[power_attorney]city', city);
    originState && formData.append('[power_attorney]state', originState.name)
    formData.append('[power_attorney]zip_code', zipCode)
    formData.append('[power_attorney]phone_number', phoneNumber)
    fax && formData.append('[power_attorney]fax_number', fax)
    formData.append('[power_attorney]printed_name',printedName)
    selectedDate && formData.append('[power_attorney]date',(selectedDate).toISOString())

    if (signatureFile) {
      formData.append('[power_attorney]signature', signatureFile)
    } else {
      signaturePadFile && formData.append('[power_attorney]signature', signaturePadFile)
    }
    this.sendApiDataId = await this.apiCall({
      method: configJSON.examplePOSTAPiMethod,
      endPoint: configJSON.postAttornyPath,
      token: token,
      body: formData,
    });

  }
   apiCall = async (data: any) => {
    const { method, endPoint, body, token } =
      data;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      token: token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getPoaData = async () => {    
    this.getApiDataId = await this.apiCall({
        method: configJSON.exampleAPiMethod,
        endPoint: configJSON.postAttornyPath,
        token: this.state.token,
      });
  };

  isFormValid = () => {
    const { email, phoneNumber,zipCode ,printedName, companyName, mcNumber, originAddress, city, originState, selectedDate, signatureFile, signaturePadFile } = this.state;
    
    const isValid =
    companyName !== "" &&
    mcNumber !== "" &&
    originAddress !== "" &&
    city !== "" &&
    originState !== null &&
    zipCode !== "" &&
    phoneNumber !== "" &&
    email !== "" &&
    selectedDate !== null &&
    printedName !== "" && 
    (signaturePadFile !== null || signatureFile !== null);

    this.setState({ isDisable: !isValid }); 
  };
  
  handleDateChange = (date: Date | null) => {
    const formattedDate = date && new Date(date);
    this.setState((prevState) => ({
      selectedDate: formattedDate,
      inputErrors: { ...prevState.inputErrors, [`selectedDate`]: '' },
    }),()=>{
      this.isFormValid()
    }
  )
  };
  handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if(name === 'zipCode') {
      if (!(/^\d*$/.test(value))) {
        return
      }
    }
    if(name === 'phoneNumber'){
      if(!(/^\+?\d*$/.test(value))){
        return
      }
    }
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
      inputErrors: { ...prevState.inputErrors, [`${name}`]: '' },
    }) as Pick<S, keyof S>, 
    ()=> this.isFormValid());
  };

  handleOriginCityChange = (event: React.ChangeEvent<{}>, newValue: CityList | null) =>{
    newValue && this.setState((prevState) => ({
      originState: newValue,
      inputErrors: { ...prevState.inputErrors, [`originState`]: '' },
    }), 
    ()=> this.isFormValid())
  }
  authPageTitle = (children :string) => {
    return (
      <label 
        style={{ lineHeight: 2, fontSize: 14, fontWeight: 700, fontFamily: 'Inter' }}
      >
        {children}
      </label>
    );
  };
  // Customizable Area End
}
