import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  ScrollView,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ActivityIndicator,
} from "react-native";
import CustomButton from "../../../components/src/CustomButton.web.";
import {
  Box
} from "@mui/material";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const descriptionData = this.state.description
    return (
      <>
        <div style={styles.modalBackdrop}>
        <style>{`
          p::-webkit-scrollbar {
            display: none;
          }
          p {
            scrollbar-width: none; 
            ms-overflow-style: none; 
          }
        `}</style>
        <div style={styles.modalContent}>
          <button style={styles.modalClose} onClick={this.props.onClose}>&times;</button>
          <h2 style={{fontFamily:'Inter',fontSize:'24px',marginTop:'55px'}} data-test-id="termsandcondition">Terms and Conditions</h2>
          <p style={{maxHeight:'50vh',overflowY: 'auto',width:'580px',lineHeight:'35px' }}>
            <Box dangerouslySetInnerHTML={{__html: descriptionData}}></Box>
          </p>
          <div style={{ border:'1px solid #E2E8F0 ', borderWidth:'0px 0px 1px 0px', width:'105.5%',position:'relative',right:'15px'}}>
          </div>
          <div style={styles.modalActions}>
            <CustomButton
                  text="CANCEL"
                  testId="termAndcon"
                  onClick={this.props.onClose }
                  customeWidth={true}
                />
          </div>
        </div>
      </div>
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  modalBackdrop: {
    position: 'fixed' as 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px 8px 32px 8px',
    width: '600px',
    position: 'relative' as 'relative',
    fontFamily:'Inter',
    fontSize:'20px',
    fontWeight:400,
    color:'#0F172A',
    paddingLeft:'40px'
  },
  modalClose: {
    position: 'absolute' as 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
  },
  modalActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px',
  },
  modalButton: {
    padding: '10px 20px',
    marginRight: '10px',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontFamily:'inter',
    fontSize:'16px',
    fontWeight:700,
    color:'#B57730',
    width:'117px'
  },
  declineButton: {
    backgroundColor: '#FDF1E2',
  },
};
// Customizable Area End
