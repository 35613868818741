import React from "react";

// Customizable Area Start
import { View, StyleSheet, ViewStyle, TextStyle } from "react-native";

import { Input, Button, Typography } from '@builder/component-library';
import Box from '@mui/system/Box';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthPageTitle } from "../../../components/src/AuthPageTitle.web";
import { GeneralCenterText, PageTitle } from "../../../components/src/CustomText.web";
import UnauthLayout from "../../../components/src/UnauthLayout.web";
import CustomButton from "../../../components/src/CustomButton.web.";
import SocialLoginSection from "../../../components/src/SocialLoginSection.web.";
import CustomStyledCheckbox from "../../../components/src/CustomStyledCheckbox.web.";

const newStyles = {
  mainContainer: { 
    display: 'flex', 
    flexDirection: 'row', 
    minHeight: '100vh', 
    width: '100vw', 
    paddingVertical: '16px', 
    paddingRight: '15px' 
  },
  formContainer: {
    marginLeft: 'auto', 
    marginRight:'auto', 
    width: '25%', 
    minWidth: '370px', 
    justifyContent: 'center', 
    padding: '32px'
  },
  bannerContainer: {
    position: 'relative', 
    width: '50%', 
  },
  actionWrapper: { 
    marginTop: '8px', 
    marginBottom: '54px', 
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center', 
    justifyContent: 'space-between'
  },
  inputContainer: { 
    paddingHorizontal: '8px', 
    paddingVertical: '10px', 
    height: '56px', 
    borderRadius: 8, 
    borderStyle: 'solid', 
  },
  inputText: { fontSize: 16, fontFamily: 'Inter', fontWeight: '400' },
  customLink: {
    color: '#215089',
    fontFamily: 'Arial, Helvetica',
    fontSize: '16px',
    fontWeight: 'bold',
    textDecoration: 'none',
    cursor: "pointer"
  },
  checkboxWrapper: { display: 'inline-flex', alignItems: 'center', gap: 8, cursor: 'pointer' },
  checkbox: {
    accentColor: '#B57730',
    width: '20px',
    height: '20px',
    borderRadius: '6px',
    borderWidth: '1px',
    borderColor: '#64748B',
    borderStyle: 'solid',
    verticalAlign: 'middle',
    backgroundSize: 'cover',
    backgroundPosition: 'center center' 
  },
  checkboxLabel: { fontFamily: 'Inter', fontWeight: '400', fontSize: '14px' },
  gradientButtonWrapper: { 
    backgroundImage: 'linear-gradient(99deg, #915F26 0%, #F19F40 100%)', 
    borderRadius: '8px', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center' 
  },
  gradientButtonInnerLayout: { padding: '10px 16px', width: '100%', justifyContent: 'center', alignItems: 'center' },
  orText: { textAlign: "center", marginTop: 20, marginBottom: 27 },
  horizontalCenterContainer: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonWithIcon: { 
    border: '1px solid #CBD5E1',
    borderRadius: 8,
    padding: 8,
    backgroundColor: '#FFFFFF',
    gap: '8px'  
  },
  normalText: { fontFamily: 'Inter', fontWeight: '400' },
  smallText: { fontFamily: 'Arial, Helvetica', fontSize: 14 },
  signupWrapper: { 
    marginTop: '27px', 
    width: '100%', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center' 
  },
  bannerBg: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
  },
  bannerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(33, 80, 137, 0.50)',
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
  },
  logoWrapper: { flexShrink: 1, backgroundColor: '#DDE1ED', borderRadius: 10, padding: '17px' },
  logoImg: { borderRadius: 10 },
  toastContainer: { 
    width: '100%', 
    backgroundColor: "#FEE2E2", 
    position: "relative", 
    borderRadius: '4px' ,
    top:'90px'
  },
  toastDecoration: { 
    width: 5, 
    height: "100%", 
    background: '#DC2626', 
    position: "absolute", 
    borderRadius: "8px 0 0 8px" 
  },
  toastMessageText: { 
    fontFamily: 'Arial, Helvetica', 
    fontSize: 16, 
    fontWeight: '400', 
    marginTop: 0, 
    padding: "12px 16px", 
    color: '#DC2626' 
  },
  mainTitle:{
    marginTop:"40%"
  }
}
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  errorToast = () => {
    return this.state.emailError &&
      <Box sx={newStyles.toastContainer}>
        <Box sx={newStyles.toastDecoration} />
        <Typography style={newStyles.toastMessageText as TextStyle}>
          {this.state.toastMessage}
        </Typography>
      </Box>
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <UnauthLayout currentWidth={this.state.width}>
        {this.errorToast()}
        <PageTitle component="h2" variant="2xl" style={newStyles.mainTitle}>
          {this.labelTitle}
        </PageTitle>
        <View style={{ marginTop: '22px' }}>
          <View style={{ marginBottom: '16px' }}>
            <AuthPageTitle>Email / Mobile Number</AuthPageTitle>
            <Input
              borderColor="#CBD5E1"
              data-test-id="txtInputEmail"
              borderHighlightColor="#FBE1C4"
              borderErrorColor="#F87171"
              placeholderTextColor="#94A3B8"
              style={{ flex: 1, outline: 'none' } as TextStyle}
              containerStyle={newStyles.inputContainer as ViewStyle}
              textStyle={newStyles.inputText as TextStyle}
              placeholder={"Your email"}
              value={this.state.email}
              error={this.state.emailError ? this.state.errorMessage : ""}
              errorStyle={{ fontFamily:'Inter',fontSize:12,margin:1,marginTop:5 }}
              onChangeText={(text) => this.handleChangeEmail(text)}
            />
          </View>
          <View>
            <AuthPageTitle>Password</AuthPageTitle>
            <Input
              data-test-id="txtInputPassword"
              secureTextEntry={this.state.enablePasswordField}
              borderErrorColor="#F87171"
              borderColor="#CBD5E1"
              placeholder={"Your password"}
              borderHighlightColor="#FBE1C4"
              placeholderTextColor="#94A3B8"
              style={{ flex: 1, outline: 'none' } as TextStyle}
              containerStyle={newStyles.inputContainer as ViewStyle}
              value={this.state.password}
              textStyle={newStyles.inputText as TextStyle}
              error={this.state.passwordError ? this.state.passwordMessage || 'Password is required' : ""}
              errorStyle={{ fontFamily:'Inter',fontSize:12,margin:1,marginTop:5  }}
              onChangeText={(text) => this.handleChangePassword(text)}
              rightIcon={
                <Button
                aria-label="toggle password visibility"
                data-test-id="btnTogglePassword"
                  onPress={this.handleClickShowPassword}
                  style={[styles.removeBackground]}
                  textStyle={{color:'#94A3B8'}}
                  icon={this.state.enablePasswordField ? (
                    <VisibilityOffOutlinedIcon style={{ color: "#94A3B8" }} />
                  ) : (
                    <RemoveRedEyeOutlinedIcon style={{ color: "#94A3B8" }} />
                  )}
                />
              }
            />
          </View>
          <Box sx={newStyles.actionWrapper}>
            <CustomStyledCheckbox
              name="btnRememberMe"
              label="Remember me"
              checked={this.state.checkedRememberMe} 
              onChange={(value) => this.setRememberMe(!this.state.checkedRememberMe)} 
            />
            <span 
              data-test-id={"btnForgotPassword"} 
              onClick={this.toForgotPassword} 
              style={newStyles.customLink}>
                Forgot Password?
            </span>
          </Box>
          <CustomButton 
            text="SIGN IN"
            testId="btnEmailLogIn" 
            onClick={this.login} 
          />
          <div style={{display:'none'}}>
           <GoogleOAuthProvider clientId="649592030497-gp3moqh0k2src5r2u5qfaorii1dqkvdc.apps.googleusercontent.com">
            <SocialLoginSection
              onSuccess={this.handleSuccess}
              onError={this.handleError}
            />
          </GoogleOAuthProvider>
          </div>
          <Box 
            data-test-id="btnSignUp"
            onClick={this.goToSignUp} 
            sx={newStyles.signupWrapper}>
            <GeneralCenterText style={newStyles.smallText as TextStyle}>
              {this.textSignup} <span style={newStyles.customLink}>Sign Up</span>
            </GeneralCenterText>
          </Box>
        </View>
      </UnauthLayout>
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
