//@ts-nocheck
import React from "react";
// Customizable Area Start
import { IconButton, Box, Typography, Divider, Grid, TextField, Autocomplete, AutocompleteProps, Paper, PaperProps, ListItem, ListItemText,Button} from "@mui/material";
import HeaderNavigation from "../../navigationmenu/src/HeaderNavigation.web";
import { KeyboardArrowDown, CalendarToday ,FileUploadOutlined, CheckCircleRounded, Close} from "@mui/icons-material";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import ReactSignatureCanvas from "react-signature-canvas";

import {
  styled, createTheme, ThemeProvider
} from "@mui/material/styles";

const theme = createTheme({
  components: {
   
  },
});

function SelectState(onHandleChance: OnHandleChance, stateName: CityList | null) {
  return <StyledStateAutocomplete
    id="country-select-demo"
    data-test-id="selectState"
    options={configJSON.StatesName}
    autoHighlight
    PaperComponent={CustomPaper}
    onChange={onHandleChance}
    getOptionLabel={(option) => option.name}
    isOptionEqualToValue={(option, value) => option.code === value.code}
    value={stateName}
    renderOption={(props, option) => {
      return (
        <ListItem
          {...props}
          style={{
            padding: '12px 16px',
            borderRadius: '8px',
            cursor: 'pointer',
          }}
        >
          <ListItemText
            sx={{ color: '#0F172A', fontSize: '14px', fontWeight: 400, fontFamily: 'Inter' }}
            primary={option?.name}
          />
        </ ListItem>
      );
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        placeholder="Enter State"
        InputProps={{
          ...params?.InputProps,
          endAdornment: (<KeyboardArrowDown />)
        }}
        variant="outlined"
      />
    )}
  />
}

const CustomPaper = (props:PaperProps) => {
  return <Paper elevation={8} {...props} />;
};
// Customizable Area End

import InvoiceBillingController, {
  Props,
  configJSON,
} from "./InvoiceBillingController.web";

export default class InvoiceBilling extends InvoiceBillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box sx={webStyles.container}>
        <HeaderNavigation navigation={this.props.navigation} id={""} />
        <Box sx={webStyles.content}>
          <ThemeProvider theme={theme}>
            <Box sx={webStyles.mainContent}>
              <Box sx={webStyles.tabContainer}>
                <Typography style={webStyles.headerFont}>{configJSON.powerText}</Typography>
                <Divider style={{color:'#DEE5ED', height:'2px'}}/>
                <Grid container sx={{ mt: '15px' }}>
                  <Grid container spacing={3} >

                    <Grid item xs={12} sm={6} md={5} lg={4} sx={{ mb: {xs:'', sm:2, md:2}, marginTop: {xs:'5px', sm:'15px', md:'15px'}, marginRight:{xs:'', sm:'', md:'35px'} }} >
                      {this.authPageTitle('Company Name')}
                      <TextField
                        data-test-id="txtInputCompanyName"
                        style={webStyles.inputContainer}
                        placeholder={"Company Name"}
                        name="companyName"
                        value={this.state.companyName}
                        onChange={this.handleChange}
                      />
                      <Typography sx={webStyles.inputErrorMessage}>{this.state.inputErrors.companyName}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={5} lg={4} sx={{ mb: {xs:2, sm:2, md:2} ,marginTop:{xs:'', sm:'15px', md:'15px' }}} >
                      {this.authPageTitle('MC Number')}
                      <TextField
                        data-test-id="txtmcNumber"
                        style={webStyles.inputContainer}
                        placeholder={"Enter MC Number"}
                        name="mcNumber"
                        value={this.state.mcNumber}
                        onChange={this.handleChange}
                      />
                      <Typography sx={webStyles.inputErrorMessage}>{this.state.inputErrors.mcNumber}</Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} >
                    <Grid item xs={12} sm={6} md={5} lg={4} sx={{ mb: {xs:'', sm:2, md:2}, marginTop: {xs:'5px', sm:'15px', md:'15px'}, marginRight:{xs:'', sm:'', md:'35px'} }}>
                      {this.authPageTitle('Address')}
                      <TextField
                        data-test-id="Address"
                        style={webStyles.inputContainer}
                        placeholder={"Enter Address"}
                        name="originAddress"
                        value={this.state.originAddress}
                        onChange={this.handleChange}
                      />
                      <Typography sx={webStyles.inputErrorMessage}>{this.state.inputErrors.originAddress}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={5} lg={4} sx={{ mb: {xs:2, sm:2, md:2} ,marginTop:{xs:'', sm:'15px', md:'15px' }}}>
                      {this.authPageTitle('City')}
                      <TextField
                        data-test-id="City"
                        style={webStyles.inputContainer}
                        placeholder={"Enter City Name"}
                        name="city"
                        value={this.state.city}
                        onChange={this.handleChange}
                      />
                      <Typography sx={webStyles.inputErrorMessage}>{this.state.inputErrors.city}</Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} >
                    <Grid item xs={12} sm={6} md={5} lg={4} sx={{ mb: {xs:'', sm:2, md:2}, marginTop: {xs:'5px', sm:'15px', md:'15px'}, marginRight:{xs:'', sm:'', md:'35px'} }}>
                        <Typography sx={webStyles.title}>State</Typography>
                          {SelectState(this.handleOriginCityChange, this.state.originState)}
                        <Typography sx={webStyles.inputErrorMessage}>{this.state.inputErrors.originState}</Typography>               
                    </Grid>
                    <Grid item xs={12} sm={6} md={5} lg={4} sx={{ mb: {xs:2, sm:2, md:2} ,marginTop:{xs:'', sm:'15px', md:'15px' }}}>
                    {this.authPageTitle('Zip Code')}
                      <TextField
                        data-test-id="ZipCode"
                        style={webStyles.inputContainer}
                        placeholder={"Enter Zip Code"}
                        name="zipCode"
                        value={this.state.zipCode}
                        onChange={this.handleChange}
                      />
                      <Typography sx={webStyles.inputErrorMessage}>{this.state.inputErrors.zipCode}</Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} >
                    <Grid item xs={12} sm={6} md={5} lg={4} sx={{ mb: {xs:'', sm:2, md:2}, marginTop: {xs:'5px', sm:'15px', md:'15px'}, marginRight:{xs:'', sm:'', md:'35px'} }}>
                    {this.authPageTitle('Phone Number')}
                      <TextField
                        data-test-id="PhoneNumber"
                        style={webStyles.inputContainer}
                        placeholder={"Enter Phone Number"}
                        name="phoneNumber"
                        value={this.state.phoneNumber}
                        onChange={this.handleChange}
                      />
                      <Typography sx={webStyles.inputErrorMessage}>{this.state.inputErrors.phoneNumber}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={5} lg={4} sx={{ mb: {xs:2, sm:2, md:2} ,marginTop:{xs:'', sm:'15px', md:'15px' }}}>
                    {this.authPageTitle('Fax')}
                      <TextField
                        data-test-id="fax"
                        style={webStyles.inputContainer}
                        placeholder={"Enter Fax Number"}
                        name="fax"
                        value={this.state.fax}
                        onChange={this.handleChange}
                      />
                      <Typography sx={webStyles.inputErrorMessage}>{this.state.inputErrors.fax}</Typography>
                    </Grid>
                  </Grid>
                  
                  <Grid container spacing={3} >
                    <Grid item xs={12} sm={6} md={5} lg={4} sx={{ mb: {xs:'', sm:2, md:2}, marginTop: {xs:'5px', sm:'15px', md:'15px'}, marginRight:{xs:'', sm:'', md:'35px'} }}>
                      {this.authPageTitle('Email Address')}
                      <TextField
                        data-test-id="email"
                        style={webStyles.inputContainer}
                        placeholder={"Enter Email Address"}
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                      <Typography sx={webStyles.inputErrorMessage}>{this.state.inputErrors.email}</Typography>
                    </Grid>                    
                  </Grid>

                </Grid>

                <Box style={{marginTop:"30px"}}>
                  <Typography sx={webStyles.ruleText}>{configJSON.rule1}</Typography>
                  <Typography sx={{...webStyles.ruleText, marginTop:'7px'}}>{configJSON.rule2}</Typography>
                  <ul>
                    <li><Typography sx={{...webStyles.ruleText,}}>{configJSON.list1}</Typography></li>
                    <li><Typography sx={{...webStyles.ruleText,}}>{configJSON.list2}</Typography></li>
                    <li><Typography sx={{...webStyles.ruleText,}}>{configJSON.list3}</Typography></li>                    
                  </ul>
                  <Typography sx={{...webStyles.ruleText, marginTop:'7px'}}>{configJSON.rule3}</Typography>
                  <Typography sx={{...webStyles.ruleText, marginTop:'7px'}}>{configJSON.rule4}</Typography>
                  <Typography sx={{...webStyles.ruleText, marginTop:'7px'}}>{configJSON.rule5}</Typography>
                  <Typography sx={{...webStyles.ruleText, marginTop:'7px'}}>{configJSON.rule6}</Typography>
                </Box>

                <Grid container spacing={3} >
                    <Grid item xs={12} sm={6} md={5} lg={4} sx={{ mb: 2, marginTop:'35px',}}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']} sx={{ width: '100%' }}>
                          <DatePicker
                            format="YYYY-MM-D"
                            data-test-id = "selectDate"
                            onChange={this.handleDateChange}
                            value={this.state.selectedDate}
                            slots={{
                              openPickerIcon: CalendarToday,
                            }}
                            maxDate={dayjs()}
                            slotProps={{
                              openPickerIcon: { fontSize: '20px' },
                              openPickerButton: {
                                sx: {
                                  color: '#0F172A',
                                },
                              },
                              textField: {
                                placeholder: 'select date',
                                hiddenLabel: true,
                                sx: {
                                  width: '100%',
                                  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                                    border: '1px solid #CBD5E1',
                                  },
                                  '& .MuiInputBase-input::placeholder': {
                                    color: '#475569',
                                    opacity: 1,
                                  },
                                },
                              }
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    <Typography sx={webStyles.inputErrorMessage}>{this.state.inputErrors.selectedDate}</Typography>               
                    </Grid>

                </Grid>
                <Grid container spacing={3} >
                  <Grid item xs={12} sm={6} md={5} lg={4} sx={{ mb: 2, marginTop: '15px',display:'flex', flexDirection:'column'}}>
                  {this.authPageTitle('Insert your Signature here')}
                    <ReactSignatureCanvas
                      penColor="black"
                      ref={this.state.sigCanvasRef}
                      canvasProps={{                        
                        className: "sigCanvas",
                        style:{ ...webStyles.canvasStyles },
                      }}
                      onBegin = {this.handleOnBegin}
                      onEnd = {this.handleSignaturePad}
                    />
                  </Grid>
                </Grid>
                {this.authPageTitle('OR')}
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Button onClick={this.handleUploadPicture} data-test-id="UploadPicture" style={{ ...webStyles.uploadStyle, textTransform: 'none' }}>
                    <input
                      style={{ display: 'none', }}
                      accept="image/*"
                      id="upload-photo"
                      type="file"
                      ref={this.state.fileInputRef}
                      onChange={this.handleImageChange}
                    />
                    <FileUploadOutlined sx={{ mr: 2 }} />
                    Upload Signature
                  </Button>
                  {this.state.signatureFile && <Typography sx={{ ml: 1, fontFamily: 'Inter', fontWeight: 500, fontSize: '16px', color: '#64748B' }}>{this.state.signatureFile.name}</Typography>}
                </Box>
                <Typography sx={webStyles.inputErrorMessage}>{this.state.inputErrors.signatureFile}</Typography>               

                <Grid container spacing={3} >
                    <Grid item xs={12} sm={6} md={5} lg={4} sx={{ mb: 2, marginTop:'15px',}}>
                      {this.authPageTitle('Printed Name')}
                      <TextField
                        data-test-id="printedName"
                        style={webStyles.inputContainer}
                        placeholder={"Enter Name"}
                        name="printedName"
                        value={this.state.printedName}
                        onChange={this.handleChange}
                      />
                      <Typography sx={webStyles.inputErrorMessage}>{this.state.inputErrors.printedName}</Typography>               
                    </Grid>
                </Grid>
                <div style={{ marginTop: '35px', display: 'flex', justifyContent: 'center'}}>
                  <Button
                    data-test-id="submitBtnWrapper"
                    onClick={() => { this.handleSubmitForm()}}
                    style={{...webStyles.innerLayout, background:'linear-gradient(99deg, #915F26 0%, #F19F40 100%)', opacity: this.state.isDisable ? 0.5 : 1}}
                    disabled={this.state.isDisable}
                  >
                    SUBMIT
                  </Button>
                </div>
                {this.state.successMsg && <div style={webStyles.modalBackdrop}>
                  <div style={webStyles.modalContent}>
                    <div style={webStyles.trueIconBox}>
                      <IconButton disabled>
                        <CheckCircleRounded sx={{ color: '#34D399' }} />
                      </IconButton>
                      <p style={webStyles.successMsg}>
                        {this.state.successMsg}
                      </p>
                    </div>
                  </div>
                </div>}
                {this.state.errorMsg && <div style={webStyles.modalBackdrop}>
                  <div style={webStyles.modalContent}>
                    <div style={webStyles.trueIconBox}>
                      <div style={webStyles.crossImage}> 
                        <Close style={{color:'#FFF', fontSize:'24px'}}/>
                      </div>
                      <p style={webStyles.successMsg}>
                        {this.state.errorMsg}
                      </p>
                    </div>
                  </div>
                </div>}
              </Box>
            </Box>
          </ThemeProvider>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledStateAutocomplete = styled((props: AutocompleteProps<CityList, false, false, false>) => (
  <Autocomplete {...props} />
))({
  '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      border: '1px solid #BDC5CF',
      marginTop:'6px',
      height:'60px',
      color: '#475569',
      fontSize: '16px',
      background:"#fff",
      paddingRight:"10px!important",
      display:"flex",
      justifyContent:"space-between",
      fontFamily: 'Inter',
      fontWeight: 400,
      '& fieldset': {
        border: '1px solid #BDC5CF',
      },
  },
  '& .MuiInputBase-input': {
      background:"#fff",
      padding: '10px 8px'
  },
  ".css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator":{
      display:"none"
  },
});

const webStyles = {
  trueIconBox : { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center' },
  crossImage: { backgroundColor: '#F87171', borderRadius: '50%', padding: '3px', margin: '10px',height:'25px', width:'25px' },
  successMsg: { fontSize: '20px', fontWeight: 400, fontFamily: "Inter", color: '#0F172A' },
  modalContent: {
    backgroundColor: '#fff',
    borderRadius: '8px 8px 8px 8px',
    width: 'max-content',
    padding: '0px 20px',
    marginBottom: '100px',
  },
  modalBackdrop: {
    position: 'fixed' as 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  uploadStyle:{borderRadius: '8px',background:'#F1F5F9',fontFamily:'Inter',fontWeight:700,fontSize:'16px',width:'238px',height:'57px', color:'#64748B'},
  innerLayout: {
    paddingHorizontal: 16, 
    paddingVertical: 10, 
    justifyContent: 'center', 
    alignItems: 'center' ,
    color:'white',
    fontWeight:700,
    fontSize:'16px',
    height:'100%',
    backgroundColor: 'transparent',
    width:'200px',
    borderRadius:'8px',
  },
  canvasStyles : {
    display:'block',width:'100%', height: '56px', backgroundColor: "#E2E8F0",border:'1px solid #CBD5E1', borderRadius: 8, marginTop: '10px'
  },
  ruleText:{
    fontSize: 16,
    fontWeight: '400',
    fontFamily: 'Inter',
    color:'#475569',
    lineHeight: '25px',
  },
  title:{
    lineHeight: 2,
    fontSize: 14,
    fontWeight: '700',
    fontFamily: 'Inter'
  },
  inputErrorMessage:{
    fontSize:12,
    color:"red",
    fontFamily:"inter",
    marginTop:'7px'
  },
  inputContainer: {
    paddingHorizontal: '8px',
    marginTop:'6px',
    paddingVertical: '10px',
    height: '56px',
    border: '1px solid #CBD5E1',
    borderRadius: 12,
    borderStyle: 'solid',
    width: '100%',
    fontSize: 16,
    fontFamily: 'Inter',
    fontWeight: '400'
  },
  content: {
    display: "flex",
    flex: 1,
  },
  mainContent: {
    flex: 1,
    padding: "2rem",
  },
  tabContainer: {
    backgroundColor: '#FFFFFF',
    padding: '2rem',
    minHeight:'650px'
  },
  headerFont: {
    marginBottom:'20px',
    fontWeight: 700,
    fontSize: '30px',
    fontFamily: "Inter",
    color: "#215089"
  },
}
// Customizable Area End
