import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface NotificationAttributes {
  headings: string;
  contents: string;
  is_read: boolean;
  created_at: string;
}

// Interface for a single notification
interface Notification {
  id: string;
  type: string;
  attributes: NotificationAttributes;
}

// Interface for the meta data
interface Meta {
  unread_count: number;
}

// Interface for the overall response structure
interface NotificationsResponse {
  data: Notification[];
  meta: Meta;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  deleteId: string;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  verifyToken: string;
  notifications: any[];
  loading: boolean;
  token: string;
  notfiy:boolean,
  tabValue:number
  anchorEl: HTMLElement | null,
  openModal:boolean,
  notificationData:Notification[];
  unreadNumber:number
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PushnotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiNotificationsCallId: string = "";
  getNotificationId: string = "";
  readNotificationId:string = "";
  deleteNotificationId :string = "";
  readAllNotificationId:string = "";
  todayDate:Date = new Date(); ;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      txtInputValue: "",
      deleteId:'',
      txtSavedValue: "A",
      enableField: false,
      verifyToken: "",
      notifications: [],
      loading: true,
      token: "",
      notfiy:false,
      tabValue:0,
      anchorEl:null,
      openModal:false,
      notificationData:[],
      unreadNumber:0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId === this.getNotificationId){
        
        this.handleNotificationRes(responseJson)
      }
      if(apiRequestCallId === this.readNotificationId){
        this.handleMarkRead(responseJson)
      }
      if(apiRequestCallId === this.deleteNotificationId){
        this.handleDelete(responseJson)
      }
      if(apiRequestCallId === this.readAllNotificationId){
        this.handleAllread(responseJson)
      }

    
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getNotification(0)
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start


  getNotification = (value:number) => {
    let status = ''
    if(value===1){
      status = 'unread'
    }
    else{
      status = 'read'
    }
    const header = {
      token: localStorage.getItem('LoginToken')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getNotificationId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_notifications/notifications?tab=${status}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleNotificationRes =( response:NotificationsResponse)=>{
    this.setState({notificationData:response.data,unreadNumber:response.meta.unread_count})
  }

  readOneNotfication = () => {
    const header = {
      "Content-Type": 'application/json',
      token: localStorage.getItem("LoginToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_notifications/notifications/${this.state.deleteId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    this.readNotificationId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleMarkRead=(response:{data:Notification})=>{
    if(response.data){
      this.setState({ anchorEl: null });
      this.getNotification(this.state.tabValue)
    }
  }

  deleteNotification = () => {
    const header = {
      "Content-Type": 'application/json',
      token: localStorage.getItem("LoginToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_notifications/notifications/${this.state.deleteId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    this.deleteNotificationId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleDelete=(response:string)=>{
    if(response){
      this.setState({ anchorEl: null });
      this.getNotification(this.state.tabValue)
    }
  }

  readAllNotification = () => {
    const currentDate = new Date()
    const final = currentDate.toISOString()
    const header = {
      "Content-Type": 'application/json',
      token: localStorage.getItem("LoginToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_notifications/notifications/mark_all_as_read?read_at=${final}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    this.readAllNotificationId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleAllread = (response:string)=>{

    if(response){
      this.getNotification(this.state.tabValue)
    }
  }

   timeAgo(createdAt: string): string {
    const currentTime = new Date();
    const createdTime = new Date(createdAt);
   
    
    const diffInSeconds = Math.floor((currentTime.getTime() - createdTime.getTime()) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
  
    if (diffInSeconds < 60) {
      return 'just now';
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`;
    } else {
      return `${diffInDays} day${diffInDays === 1 ? '' : 's'} ago`;
    }
  }

  handleclose = ()=>{
    this.setState({ anchorEl: null });
  }
  handleClick = (event:any, notificationId: string) => {
    this.setState({ anchorEl: event.currentTarget, deleteId: notificationId });
  };
  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ tabValue: newValue });
    this.getNotification(newValue)
  };
  // Customizable Area End
}
