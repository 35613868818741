import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";


// Customizable Area Start
import React from "react";
import {SelectChangeEvent} from "@mui/material";
export type NavObject = TypeNav
export interface TypeNav {
    addListener: Function
    goBack: Function,
    getParam: Function,
    navigate: Function
}
export interface LoadResponse {
    id: number;
    type: string;
    attributes: {
      load_weight: string;
      full_name:string;
      company_name: string;
      email: string;
      total_miles: string;
      load_broker_phone_number: string;
      origin_address: {
        city: string;
        state: string;
        address: string;
        country: string;
        zip_code: string;
      };
      destination_address: {
        city: string;
        state: string;
        address: string;
        country: string;
        zip_code: string;
      };
      price: string;
      status: string;
      start_date: string;
      end_date: string;
      commodity: string;
      load_details: string;
      load_broker_id:number
    }

    errors:{
        message:string
    }
  
}

interface LoadResponse2 {
    data: {
      id: number;
      status?: string;
      load_broker_id: number;
      start_date: string;
      end_date: string;
      price: string;
      load_broker_phone_number: string;
      commodity: string;
      email: string;
      load_details: string;
      load_weight: string;
      total_miles: string;
      origin_address: {
        city: string;
        state: string;
        address: string;
        country: string;
        zip_code: string;
      };
      destination_address: {
        city: string;
        state: string;
        address: string;
        country: string;
        zip_code: string;
      };
      load_broker_credit_score: number;
      created_at: string;
      updated_at: string;
    };
    meta: {
      message: string;
    };
  }

  interface ChatData {
    data: {
      id: string;
      type: string;
      attributes: {
        id: number;
        twilio_channel_sid: string;
        sender_id: number;
        receiver_id: number;
        sender_account: AccountData;
        receiver_account: AccountData;
        unread_count: number;
      };
    };
    error:string
  }
  
  interface AccountData {
    data: {
      id: string;
      type: string;
      attributes: {
        activated: boolean;
        country_code: string | null;
        email: string;
        first_name: string;
        last_name: string;
        full_phone_number: string;
        phone_number: string | null;
        type: string;
        created_at: string;
        updated_at: string;
        device_id: string | null;
        unique_auth_id: string;
        profile_data: ProfileData;
        user_profile_image: string;
      };
    };
  }
  
  interface ProfileData {
    truck_type?: string[];
    insurance?: string;
    mc_number?: string;
    driving_license_number?: string;
    authority?: string;
  }


  interface IUserList {
    "id": string,
    "type": string,
    "attributes": {
      "twilio_channel_sid": string,
      "sender_id": number,
      "receiver_id": number,
      "latest_message_id": number,
      "latest_message_content": string,
      "latest_message_created_at": string,
      "sender_account": {
        "data": {
          "attributes": {
            "activated": boolean,
            "first_name": string,
            "last_name": string,
            "user_profile_image": string 
          }
        }
      },
      "receiver_account": {
        "data": {
          "attributes": {
            "activated": boolean,
            "first_name": string,
            "last_name": string,
            "user_profile_image": string 
          }
        }
      },
      "unread_count": number,
      "other_user_is_online":boolean,
    }
  }

  interface IPerticularMessages {
    "id": string,
    "twilio_channel_sid": string,
    "sender_id": number,
    "receiver_id": number,
    "other_user_is_online": boolean,
    "unread_count": boolean,
    "messages": IMessageList[],
    "user_profile_image":string,
    "first_name":string,
    "last_name":string,
    }

    export interface IMessageList {
      "id": string,
      "attributes": {
        "message": string,
        "account_id": number,
        "created_at": string,
        "is_mark_read": boolean,
        "attachments": null | {
          url:string,
          id:string,
        }[]
      }
    }

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: NavObject;
    id: string;
    // Customizable Area Start

    // Customizable Area End
}


interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    fullName: string;
    loginemail: string;
    name: string;
    template_dis: string;
    phoneNumber: string;
    loginpassword: string;
    showPassword: boolean;
    errors: {
        [key: string]: string;
    };
    registrationSuccess: boolean;
    isModalOpen: boolean,
    isModelFuntionCall: boolean,
    isEditBtn: boolean,
    getUserData: [],
    getShowUserData: {
        attributes: {
            comment: string; id: number, name: string, description: string, status: string,
        };
    },
    getUserDataArchive: [],
    isArchiveModalOpen: boolean
    getorganizationsDetails: [],

    //create Quote
    itemName: string,
    itemPrice: string,
    itemQuantity: string,
    discount: string,
    description: string,
    //PUT for Achive
    serialIdforArchive: string,
    //Level 1
    leveOneModal: boolean;
    selectedRowData: {},
    statusForlevelOne: string;
    selectedStatus: string | null;
    userRole: string;
    userRolelevelOne: string;
    anchorEl: null,
    selectedOption: string,
    isEditModalOpen: boolean,
    isShowModalOpen: boolean,
    isStatusModalOpen: boolean,
    editClickDataLeveZero: [],
    itemNameEdit: string,
    itemPriceEdit: string,
    itemQuantityEdit: string,
    discountEdit: string,
    descriptionEdit: string,
    serialIdEdit: number;
    openDialog: boolean;
    total_page: number;
    currentPage: number;
    itemsPerPage: number;
    statusBtn: boolean;
    status: string;
    tabValue:number,
    selectedCardId:number
    cards:LoadResponse[]
    openModal:boolean
    chatS:boolean
    showToast:boolean
    toastRef : any
    userList:IUserList[],
    particularChat :IPerticularMessages;
    inputMessage:string;
    fileUploaded: Blob;
    userDatas:boolean;
    loaderId:number;
    isLoading: boolean;
    attachmentError:string
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface ApiData {
    header?: Record<string, string>;
    contentType?: string;
    method: string;
    endPoint: string;
    body?: LoginPayload | TemplateData | Payload | TemplateIdData | FormData// Adjust the type of body as needed
    type?: string;
}

interface LoginPayload {
    data: {
        email: string;
        password: string;
    };
}

interface TemplateData {
    template: {
        name: string;
        description: string;
    };
}

interface Payload {
    template: {
        name: string;
        description: string;
        kpis_attributes: {
            [key: number]: {
                name: string;
                description: string;
                point: number;
            };
        };


    };
}

interface ChangeEvent {
    target: {
        name: string;
        value: string;
    };
}


interface TemplateIdData {
    status: string;
    comment: string;
}

interface ChangeEvent {
    target: {
        name: string;
        value: string;
    };
}

export interface EventList {
    id: number,
    attributes: {
        name: string,
        description: string,
        status: string,
        comment: string,

    }
}



export default class MultilevelApprovalController extends BlockComponent<
    Props,
    S,
    SS
>
{

    // Customizable Area Start
    AttendancDetailsApi: string = "";
    AttendancePostApi: string = "";
    AttendancDetailsArchivedApi: string = "";
    templatesApi: string = "";
    ArchivedDataPutApi: string = "";
    UpdateApiPutApi: string = "";
    StatusDataPutApi: string = "";
    loginPostDeatilsApi: string = "";
    editUpdateLevelZeroApi: string = "";
    //Level 1
    getListApi: string = "";
    getShowListApi: string = "";
    levelOneTestingApi: string = "";
      showAllLoadsAPICallId:string = ''
      updateStatusId:string = ''
      createChat:string = ''
      getPerticularUsersChatAPIcallID:string = ''
      allChatListId:string = ""
      sendMessageAPICallId:string = ""
      getuserAccountId:string = ""
    statuses= [
        {
            "value": "pending",
            "label": "Pending"
        },
        {
            "value": "in_transit",
            "label": "In transit"
        },
        {
            "value": "road_closure",
            "label": "Road closure"
        },
        {
            "value": "break_down",
            "label": "Break down"
        },
        {
            "value": "weather_delay",
            "label": "Weather delay"
        },
        {
            "value": "delivered",
            "label": "Delivered"
        },
        {
          "value":'unknonw',
          "label":'unknown'
        },
        {
          "value":"done",
          "label":"done"
        },
        {
          "value":"working",
          "label":"Working"
        }
      ]
      loginId:string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            fullName: "",
            loginemail: "",
            isLoading: true,
            name: "",
            template_dis: "",
            phoneNumber: "",
            loginpassword: "",
            showPassword: false,
            errors: {},
            registrationSuccess: false,
            isModalOpen: false,
            isModelFuntionCall: false,
            isEditBtn: false,
            getUserData: [],
            getShowUserData: {
                attributes: {
                    id: 0,
                    name: '',
                    description: '',
                    status: '',
                    comment: ''
                }
            },
            getUserDataArchive: [],
            isArchiveModalOpen: false,
            getorganizationsDetails: [],
            itemName: '',
            itemPrice: '',
            itemQuantity: '',
            discount: '',
            description: '',
            //Put for Archive data
            serialIdforArchive: '',
            //Level 1
            leveOneModal: false,
            selectedRowData: '',
            statusForlevelOne: '',
            selectedStatus: null,
            userRole: '',
            userRolelevelOne: '',
            anchorEl: null,
            selectedOption: "",
            isEditModalOpen: false,
            isStatusModalOpen: false,
            isShowModalOpen: false,
            editClickDataLeveZero: [],
            itemNameEdit: '',
            itemPriceEdit: '',
            itemQuantityEdit: '',

            discountEdit: '',
            descriptionEdit: '',
            serialIdEdit: 0,
            openDialog: false,
            total_page: 0,
            itemsPerPage: 5,
            currentPage: 1,
            statusBtn: false,
            status: '',
            tabValue:0,
            selectedCardId:this.state?.cards[0]?.id,
            cards:[],
            openModal:false,
            chatS:false,
            showToast:false,
            toastRef : React.createRef(),
            userList:[],
            particularChat:{} as IPerticularMessages,
            inputMessage:" ",
            fileUploaded: {} as Blob,
            userDatas:false,
            loaderId:0,
            attachmentError:''
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start

        // Customizable Area End
    }



    // Customizable Area Start

    async componentDidMount(): Promise<void> {
        const tempVAlue = 0
        this.showAllLoads(tempVAlue)
        this.loginId = await getStorageData("LoginId");
        document.addEventListener('mousedown', this.handleClickOutside);
        this.getUserData()

    }

    componentDidUpdate(prevProp: Props, prevState: S) {
        
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson && !responseJson.errors) {
                switch (apiRequestCallId) {
                    case this.showAllLoadsAPICallId:
                        this.handleAllLoadData(responseJson)
                        break;

                    case this.updateStatusId:
                        this.handleUpdateRes(responseJson)
                        break
                    case this.createChat:
                        this.handleCreateChat(responseJson)
                        break
                    case this.getPerticularUsersChatAPIcallID:
                    const senderInfo = +this.loginId === responseJson.data.attributes.sender_id
                      ? responseJson.data.attributes.receiver_account.data.attributes
                      : responseJson.data.attributes.sender_account.data.attributes;
                      const responseData = {
                        "id": responseJson.data.id,
                        "twilio_channel_sid": responseJson.data.attributes.twilio_channel_sid,
                        "sender_id": responseJson.data.attributes.sender_id,
                        "receiver_id": responseJson.data.attributes.receiver_id,
                        "other_user_is_online": responseJson.data.attributes.other_user_is_online,
                        "unread_count": responseJson.data.attributes.unread_count,
                        "messages": responseJson.data.attributes.messages.data,
                        "user_profile_image": senderInfo.user_profile_image,
                        "first_name": senderInfo.first_name,
                        "last_name":senderInfo.last_name
                      }
                      this.setState({particularChat: responseData})
                        break
                    case this.allChatListId:
                      this.setState({ userList: responseJson.data }) 
                      const {  loaderId } = this.state;
            
                      let selectedUser = this.state.userList.find(user => user?.attributes?.receiver_id === loaderId);
                      if(selectedUser== undefined){
                        selectedUser = this.state.userList.find(user => user?.attributes?.sender_id === loaderId);
                      }
                      this.getPerticularUsersChat(selectedUser?.id)
                        break
                    case this.sendMessageAPICallId:
                      this.setState({fileUploaded : {} as Blob, inputMessage:" "})
                      this.allChatsList()
                      break
                    case this.getuserAccountId:
                      this.setState({userDatas:true})
                }
            }
        }
    }

    handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ tabValue: newValue})
        this.showAllLoads(newValue)
       
      };
    handleAllLoadData=(responseJson:{ data: LoadResponse[] })=>{
        this.setState({cards:responseJson.data, isLoading: false},()=>{
          responseJson.data.length && this.setState({selectedCardId:this.state.selectedCardId ? this.state.selectedCardId : this.state.cards[0].id})
        })
    }
    isCardSelected = (cardId: number): boolean => {
        return cardId === this.state.selectedCardId;
      };
    handleCardClick = (cardId: number) => {
        this.setState({ selectedCardId: cardId });
      };
      showAllLoads = (statusVAlue:number) => {
        const header = {
          token: localStorage.getItem('LoginToken')
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.showAllLoadsAPICallId = requestMessage.messageId;
        let finalStatus = ''
        if(statusVAlue ===1){
            finalStatus = 'approved'
        }
        else if(statusVAlue ===2){
            finalStatus = 'rejected'
        }
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_load/my_loads?status=${finalStatus}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
       
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
    
    handleClickOutside = (event:any) => {
        if (this.state.toastRef && this.state.toastRef.current && !this.state.toastRef.current.contains(event.target)) {
          this.closeToast();
        }
      };
  updateStatus = () => {
    const data = {
        status:this.state.selectedStatus
        };
    
    const header = {
      "Content-Type": 'application/json',
      token: localStorage.getItem("LoginToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_load/loads/${this.state.selectedCardId}/update_status`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    this.updateStatusId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleUpdateRes(response:LoadResponse2){
    if(response.data){

        this.setState({openModal:false,showToast:true})
        this.showAllLoads(1)
    }
  }
  handleStatusChange = (event:SelectChangeEvent<string>) => {
    const value = event.target.value;
    this.setState({ selectedStatus:
      value 
      });
  };

    handleClose= ()=>{
        this.setState({openModal:false})
    }
    handleClickOpen  = ()=>{
        this.setState({ openModal:true })
    }
    backButn = ()=>{
        this.setState({chatS:false})
    }
    chatOpen = (validId :number)=>{
        this.setState({chatS:true})
        this.handleOnSendMessage(validId)
    }
    closeToast=()=>{
        this.setState({showToast:false})
    }



    handleOnSendMessage = async(validId :number) =>{
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: await getStorageData("LoginToken")
      };
      const httpBody = {
        "data": {
          "receiver_id":validId
      }
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createChat = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_chat/chats`);
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleCreateChat = (response:ChatData)=>{
      if(response?.data?.id){
        this.getPerticularUsersChat(response?.data?.id)
      }
      else if(response.error){
        this.allChatsList()
      }
    }

    getPerticularUsersChat = async(id:string | undefined | number) => {
      
      const header = {
        "Content-Type": configJSON.advancedsearchApiContentType,
        token: await getStorageData("LoginToken")
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getPerticularUsersChatAPIcallID = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getPerticularUsersChatEndPoint}${id}`);
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    

    // All chats list 
    allChatsList = async() => {
      const header = {
        "Content-Type": configJSON.advancedsearchApiContentType,
        token: await getStorageData("LoginToken")
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.allChatListId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getPerticularUsersChatEndPoint}`);
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    formatLoadWeight = (loadWeights: string) => {
      const numericWeight = parseFloat(loadWeights);
      if (isNaN(numericWeight)) return '';

      const weight = numericWeight % 1 === 0
        ? numericWeight.toFixed(0)
        : numericWeight.toFixed(1);

      return `${weight} ${numericWeight === 1 ? 'Ton' : 'Tons'}`;
    } 
    formatLoadMiles = (miles: string) => {
      const numericMiles = parseFloat(miles);
      if (isNaN(numericMiles)) return '';

      const weight = numericMiles % 1 === 0
        ? numericMiles.toFixed(0)
        : numericMiles.toFixed(1);

      return `${weight} ${numericMiles === 1 ? 'Mile' : 'Miles'}`;
    }    
    onSendMessage = async() =>{
       if(!this.state.fileUploaded.type && !this.state.inputMessage.trim()){
        return;
      }
      const header = {
        "Content-Type": configJSON.advancedsearchApiContentType,
        token: await getStorageData("LoginToken")
      };
  
      const formData = new FormData();
  
      formData.append("data[message]", this.state.inputMessage);
      formData.append("twilio_channel_sid", this.state.particularChat.twilio_channel_sid);
      this.state.fileUploaded.type && formData.append("data[attachments][]", this.state.fileUploaded as Blob)
      
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.sendMessageAPICallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.postSendMessageApiEndPoint}`);
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  
    handleOnChange = (innerHtml: string, textContent: string, innerText: string) => {
      this.setState({
        inputMessage:innerText,
        attachmentError:""
      })
    }
    handleOnAttachClick = () => {
      const input = document.createElement('input');
      input.setAttribute("data-test-id","file_input")
      input.accept = 'image/*,application/pdf'; 
      input.type = 'file';
      input.onchange = (event) => {
        const target = event.target as HTMLInputElement; 
        const file = target.files?.[0];
        if (file) {
          this.handleFileUpload(file);
        }
      };
      input.click();
    }
  
    handleFileUpload = (file:File) => this.setState({ fileUploaded : file});
    handleOnClear = () => this.setState({ fileUploaded : {} as Blob});

    getUserData = () => {
      const header = {
        token: localStorage.getItem('LoginToken')
      };
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.getuserAccountId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_profile/profiles/fetch_user_personal_details'
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    getBorder = (cardItemId: number) =>{
      return this.isCardSelected(cardItemId) ? '2px solid #915F26' : ''
    }
    getBoxShadow = (cardItemId: number) =>{
      return this.isCardSelected(cardItemId) ? '0px 6px 4px 0px #a87f51' : '0px 4px 4px 0px #00000040'
    }
    formateDate = (date: string) => {
      const dateNew = new Date(date)
        return dateNew.toLocaleDateString('en-US', {
          weekday: 'short', 
          month: 'short',  
          day: 'numeric'   
        });
    }
    // Customizable Area End
}

